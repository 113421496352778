
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as abuseReportshwpGiMp83MMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93evFrerLL3vMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as editorzPiEvctvOiMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/blog/editor.vue?macro=true";
import { default as indexu9Y0LE4N1aMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/index.vue?macro=true";
import { default as moderation0VCp1dEgY9Meta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/moderation.vue?macro=true";
import { default as pendingBans81wvlEXXsiMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/pendingBans.vue?macro=true";
import { default as profilesQY02JArOozMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/profiles.vue?macro=true";
import { default as expensesnTQGCDLkW0Meta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexHJlIbBSyScMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewPmohhMRVopMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingykieUvmId5Meta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingVY3BSPBwPUMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/translations/missing.vue?macro=true";
import { default as usersOPfZnQ45jEMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/users.vue?macro=true";
import { default as apiOrC2IAeqOPMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/api.vue?macro=true";
import { default as _91slug_93CSYWN7LaPTMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/blog/[slug].vue?macro=true";
import { default as indexI2P337RaWAMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93yHGwV95JivMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93rxCIlowj4SMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminHuChn6hqtlMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/census/admin.vue?macro=true";
import { default as indexmHg647ZIZLMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/census/index.vue?macro=true";
import { default as contacthm6FPFnffMMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/contact.vue?macro=true";
import { default as designhgxO0yE4dNMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/design.vue?macro=true";
import { default as englishr4Q3veEBHaMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/english.vue?macro=true";
import { default as faqNHDJBFugSxMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/faq.vue?macro=true";
import { default as inclusiveZNRFQkY2FKMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/inclusive.vue?macro=true";
import { default as index_45homeYQ60yhtjg1Meta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/index-home.vue?macro=true";
import { default as index_45localejqR6VP1IYOMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/index-locale.vue?macro=true";
import { default as langswitchJyWE1zXeleMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/langswitch.vue?macro=true";
import { default as licensetJyAUETqTfMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/license.vue?macro=true";
import { default as academic9Yz6E0nsr7Meta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/links/academic.vue?macro=true";
import { default as index3lJQuv5Ba9Meta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/links/index.vue?macro=true";
import { default as mediaXBPCuP3rmDMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/links/media.vue?macro=true";
import { default as translinguisticsP5hHY23zzoMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/links/translinguistics.vue?macro=true";
import { default as zinerghoMBhAUSMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/links/zine.vue?macro=true";
import { default as names70mlkvOlghMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/names.vue?macro=true";
import { default as index6iyZENe7eUMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/nouns/index.vue?macro=true";
import { default as templatesrMlbTV1x0AMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/nouns/templates.vue?macro=true";
import { default as peopleDGpftzE6GhMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/people.vue?macro=true";
import { default as privacyCbfKohrQcQMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/privacy.vue?macro=true";
import { default as _91username_93WPLKdGoqfZMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93pTz4gHPXv3Meta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/profile/card-[username].vue?macro=true";
import { default as editordrUi3dsnvsMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/profile/editor.vue?macro=true";
import { default as anyCPhlwFdLHAMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/pronouns/any.vue?macro=true";
import { default as askVKgI3fwmjJMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/pronouns/ask.vue?macro=true";
import { default as avoidingvN5WtEvRS4Meta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/pronouns/avoiding.vue?macro=true";
import { default as indexLPvjQCNV5eMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/pronouns/index.vue?macro=true";
import { default as mirrorEpMEQmYz4uMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/pronouns/mirror.vue?macro=true";
import { default as pronounMOsLvgJzlnMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/pronouns/pronoun.vue?macro=true";
import { default as sources9Lw8nB2CJZMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/sources.vue?macro=true";
import { default as team6JMVzNSfhLMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/team.vue?macro=true";
import { default as terminologynt2KqkUP6bMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/terminology.vue?macro=true";
import { default as termsQhO1AC2pSIMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/terms.vue?macro=true";
import { default as userg6ZQgtYe2uMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/user.vue?macro=true";
import { default as workshopsm0HH6IDdrXMeta } from "/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportshwpGiMp83MMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin-blog-editor",
    path: "/admin/blog/editor",
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/blog/editor.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBans81wvlEXXsiMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiOrC2IAeqOPMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93CSYWN7LaPTMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexI2P337RaWAMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93yHGwV95JivMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93rxCIlowj4SMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminHuChn6hqtlMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexmHg647ZIZLMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contacthm6FPFnffMMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishr4Q3veEBHaMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqNHDJBFugSxMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveZNRFQkY2FKMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/inclusive.vue")
  },
  {
    name: "index-home",
    path: "/index-home",
    meta: index_45homeYQ60yhtjg1Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/index-home.vue")
  },
  {
    name: "index",
    path: "/index-locale",
    meta: index_45localejqR6VP1IYOMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/index-locale.vue")
  },
  {
    name: "langswitch",
    path: "/langswitch",
    meta: langswitchJyWE1zXeleMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/langswitch.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academic9Yz6E0nsr7Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: index3lJQuv5Ba9Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaXBPCuP3rmDMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsP5hHY23zzoMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinerghoMBhAUSMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: names70mlkvOlghMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: index6iyZENe7eUMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesrMlbTV1x0AMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleDGpftzE6GhMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyCbfKohrQcQMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93WPLKdGoqfZMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93pTz4gHPXv3Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editordrUi3dsnvsMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyCPhlwFdLHAMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askVKgI3fwmjJMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingvN5WtEvRS4Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexLPvjQCNV5eMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorEpMEQmYz4uMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounMOsLvgJzlnMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sources9Lw8nB2CJZMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: team6JMVzNSfhLMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologynt2KqkUP6bMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsQhO1AC2pSIMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userg6ZQgtYe2uMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsm0HH6IDdrXMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/2025-02-02--15-18-38/pages/workshops.vue")
  }
]